import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { AppContext } from 'src/mtska-frontend-app-scaffolding';
import { CircularProgress } from '@mui/joy';
import { Backdrop } from "@mui/material"

const dataProviderName = 'calendars/carcalendar';
const wizardName = 'Calendar management';
const wizardAction = 'calendarManagement';
const wizardObject = 'Calendar';


const CarCalendarManagement = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const { userCapabilities, existingModules } = useContext(AppContext);
    const [drawable, setDrawable] = useState(false);


    // DATA PROVIDER
    const {
        item,
        errorMessage,
        errors,
        saving,
        doAction,
    } = useDataProvider(dataProviderName, false);

    const modelName = existingModules.modelName

    const detailConfig = {
        "tabs": [
            {
                "name": "management",
                "fieldsets": [
                    {
                        "name": "management",
                        "fields": {
                            "status": {
                                "type": "select",
                                "options": [
                                    { "label": "active", "value": "active" },
                                    { "label": "paused", "value": "paused" }
                                ],
                                "required": true,
                                "className": "col-8",
                                "placeholder": "select",
                                "icon": "faChartNetwork"
                            },
                            "bookabilityStart_at": {
                                "type": "date",
                                "required": true,
                                "className": "col-2",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "bookabilityEnd_at": {
                                "type": "date",
                                "className": "col-2",
                                "placeholder": "insert",
                                "icon": "faCalendar"
                            },
                            "minimumDaysBookable": {
                                "type": "number",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faHourglass"
                            },
                            "maximumDaysBookable": {
                                "type": "number",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faHourglass"
                            },
                            "recoveryTimeAtReenter": {
                                "type": "number",
                                "required": true,
                                "className": "col-4",
                                "placeholder": "insert",
                                "icon": "faHourglass"
                            }
                        }
                    }
                ]
            }
        ],
        "validation": []
    }


    // useEffect(() => {
    //     console.info('CarPictureAssociation', params.carId)
    //     loadOneCar(params.carId).then(() => {
    //         console.info('OK CarPictureAssociation', car?.data)
    //         setDrawable(true);
    //     });
    // }, []);

    const handleSave = (data) => {
        doAction({ action: wizardAction, model: dataProviderName, data: data }).then((data) => params.handleClose(params.uid, data.item.data)).catch(() => console.error('catch handleSave'));
    }

    const car = params.car;
    return (
        !drawable ? <Backdrop open={!drawable} style={{ backgroundColor: "white" }}><CircularProgress /></Backdrop> :
            <DetailComponent
                values={{ "car_id": car?._id, "legal_entity_id": car?.legalEntity._id }}
                modelName={modelName}
                detailConfig={detailConfig}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                title={t(wizardName)}
                canSave={userCapabilities && userCapabilities[dataProviderName + '.' + wizardAction.toLowerCase()]}
                header={
                    <InfoHeader
                        view="wizard"
                        title={t(wizardName)}
                        subtitle={t('Define a {{wizardObject}} to be associated to {{carIdentifier}}', { wizardObject: wizardObject, carIdentifier: (car?.plate ? car?.plate : car?.setup?.make + " " + car?.setup?.model) })}
                    />
                }
                {...params}
            />
    )
}

export default CarCalendarManagement;


